<template>
  <b-modal
    :id="id"
    size="sm"
    title="Gửi thông báo"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleSendMessage"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group>
        <label for="InputHelp">Thông báo <span class="required">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="{required: `Thông báo là bắt buộc`}"
        >
          <b-form-textarea
            id="InputHelp"
            v-model="message"
            rows="3"
            no-resize
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { required } from '@validations'

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      message: null,
    }
  },
  methods: {
    // ...mapActions('struct', ['doCreateOrganizationStruct']),
    handleSendMessage(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleSendMessage', this.message)
        }
      })
    },
    resetState() {
      this.message = null
    },
  },
}
</script>

<style>

</style>
